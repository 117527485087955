import React from 'react'

function LandingPage() {
  return (
    <div className='index_page_wrap'>
      <div className='notice_popup_wrap'>
        <div className='system_layer_pop'>
          <div className='layer_pop_bx'>
            <strong className='tit'>
                <span>잘못된 접근</span>입니다.
            </strong>
            <p className='copy_text'>
                오류가 발생하였거나 입력하신 페이지 주소가 정확하지 않습니다.<br /> 
            </p>                              
          </div>
        </div>
      </div>
    </div>
  )
}

export default LandingPage