import React from 'react'
import './App.css';
import { useState, useEffect } from 'react';
import Axios from 'axios';
import { db } from "./firebase-config";
import { collection, addDoc, serverTimestamp, onSnapshot, query, orderBy, limitToLast, limit } from "firebase/firestore";

function SendingPage() {

  const [Headline, setHeadline] = useState("")
  const [ArticleLink, setArticleLink] = useState("")
  const [ImgUrl, setImgUrl] = useState("")
    
  const [sendList, setSendList] = useState([{ name: "Loading...", id: "initial" }]);  
  const sendCollectionRef = collection(db, "sending"); 

  const pushAdd = async () => {
    await addDoc(sendCollectionRef, { headline: Headline, article: ArticleLink, sendAt: serverTimestamp() });
  }

  // getDocs 방식 보류 - 아래 스냅식 방식이 더 나아서 교체
  // useEffect(() => {
  //   const getUsers = async () => {
  //     const data = await getDocs(sendCollectionRef);
  //     console.log(data);      
  //     setSendList(data.docs.map((doc) => ({ ...doc.data(), id: doc.id})));
  //   };      
  //   getUsers();
  // },[]);

  useEffect(() => {
    const q = query(sendCollectionRef, orderBy("sendAt", "desc"), limit(25));
    const unsub = onSnapshot(q, (snapshot) => 
      setSendList(snapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id})))
    );

    return unsub;
  },[]);
  
  //-------------
  const onHeadlineHandler = (event) => {
    setHeadline(event.currentTarget.value)
  }

  const onArticleLinkHandler = (event) => {
    setArticleLink(event.currentTarget.value)
  }

  const onImgUrlHandler = (event) => {
    setImgUrl(event.currentTarget.value)
  }

  const onSubmitHandler = (event) => {
    event.preventDefault();        

    let body = {    
      headline: Headline,
      article: ArticleLink,
      imgurl: ImgUrl,
    }

    if (Headline === "") {
      alert("기사 제목을 입력해주세요.");
      return false;
    }

    if (ArticleLink === "") {
      alert("기사 주소를 입력해주세요.");
      return false;
    }

    Axios.post('/api/push', body)    
    .then(res => {      
      if(res.data.success){
        pushAdd();
        alert("푸시가 발송됐습니다.");
        setHeadline(""); setArticleLink(""); setImgUrl("");
      } else {
        alert("발송 실패");
      }
    })
    .catch((error) => {      
      alert("서버가 응답하지 않습니다.");
    })
  
  }

  return (
    <div className="App">
      <header className="App-header">        
        <p className='App-link'>천지 푸시전송 시스템 <span>ver 2.2</span></p> 
      </header>
      <form onSubmit={onSubmitHandler}>        
        <div className='container'>        
          <label><b>헤드</b></label>
          <input type="text" value={Headline} onChange={onHeadlineHandler} placeholder="제목 넣기. 예시) [속보] 북한, 중거리급 이상 탄도미사일 발사" /> 
          <br/><br/>
          <label><b>기사 주소</b></label>
          <input type="text" value={ArticleLink} onChange={onArticleLinkHandler} placeholder="주소를 복사해 여기에 붙여넣기 https://www.newscj.com/news/articleView.html?idxno=3015389" />
          <br/><br/>
          <label><b>이미지</b>&nbsp;<span>(선택사항)</span></label>
          <input type="text" value={ImgUrl} onChange={onImgUrlHandler} placeholder="이미지 주소를 복사해 붙여넣기 https://cdn.newscj.com/news/photo/202304/3017969_3017684_4358.jpg" />
          <br/><br/>          
          <button type="submit">
            전송
          </button>        
        </div>
      </form>

      <div className='container'>
        <label><b>전송기록</b></label><br/><br/>
        {sendList.map((list, index) => {             // props에 key값을 넣어줘야 Warning이 안뜸
            return (<div key={index}>  
                {list.headline} | {list.article}               
            </div>);
        })}  
      </div>
    </div>
  )
}

export default SendingPage
