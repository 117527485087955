import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBjKQqtm06z8YvpcRWMryPr3IWNMZSOwXw",
  authDomain: "update-newscj.firebaseapp.com",
  projectId: "update-newscj",
  storageBucket: "update-newscj.appspot.com",
  messagingSenderId: "898059594556",
  appId: "1:898059594556:web:19a3967fdcf66862474c39",
  measurementId: "G-56WES01TFQ"
};

const app = initializeApp(firebaseConfig);

export const db = getFirestore(app);